.UserManual{
    overflow-y: auto;
    overflow-x: auto;
    position: fixed;
    top: 90px;
    padding: 20px;
    padding-left: 330px;
    background: #F5F3EE;
    width: 100%;
    height: calc(100% - 90px);

    .heading{
        
        span{
            color: #74613C;
            font-family: "Noto-Serif-JP";
            font-size: 20px;
        }
        
        .addContent {
            background: #74613C 0% 0% no-repeat padding-box !important;
            box-shadow: 0px 3px 6px #00000026;
            border-radius: 8px  !important;
            opacity: 1;
            height: 40px !important;
        }
    }

    .MuiTableCell-head {
        text-align: left;
        letter-spacing: 0px;
        font-weight: 500;
        font-size: 16px;
        color: #AAAAAA !important;
        opacity: 1;
        text-transform: uppercase;
    }

    .action-box{
        span{
            cursor: pointer;
        }
    }

}