.modal-content{
    border: none;
    background-color: transparent;
  }
  
  .delete-manual-modal{
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;  
    border-radius: 8px;
    width: 400px;
  
    .heading{
        color: #74613C;
        font-weight: 600;
        font-size: 20px;
        font-family: Noto-Serif-JP;
        text-align: center;
        
    }

    .content{
        letter-spacing: 0px;
        font-size: large;
        font-weight: 600;
        opacity: 1;
        text-align: center;
        font: normal normal medium 20px Gotham-Book;
        color: #000000;
    }
    
  
    .redButton {
        border: none;
        color: #FF3333;
        background: #FCE7E7 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #CCCCCCB3;
        border-radius: 8px;
        opacity: 1;
        height: 44px;
        outline: none;
        width: 100px
    }
  
    .greenButton {
        border: none;
        color: #31C52B;
        background: #E7F6E6 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #CCCCCCB3;
        border-radius: 8px;
        opacity: 1;
        height: 44px;
        outline: none;
        width: 100px

    }

  }

  
  