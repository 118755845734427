@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: gray;

.ViewGuestDinacharya {
    .loader{
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card {
        background-color: #fff;
        width: 100%;
        min-height: 790px;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);

        .card-header {
            padding: 18px 15px;
            background-color: transparent;
            border: none;

            h4 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
                margin: 0px;
            }

            .selButton {
                background: #74613C;
                border: 1px solid #74613C;
                border-radius: 8px;
                margin: 0px 6px;
                height: 45px;
                padding: 0px 20px;
                color: #fff;
                outline: none;

            }

            .react-select {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;
                cursor: default;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #31C52B;
                    background: #EFFBEE;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                        opacity: 1;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            .react-selectChange {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;
                cursor: default;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #f92e05;
                    background: #fbeeee;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
        }

        .card-body {
            padding: 0;

        }
    }
}
