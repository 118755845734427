@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: #AAAAAA;

.AddNewGroup {
    width: 100%;
    height: 780px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000001A;
    border-radius: 8px;
    opacity: 1;

    .chatListColumn {
        height: 100%;
        border-right: 1px solid $color-Light-gray;
        padding: 0px;

        .chatListHeader {
            height: 80px;
            border-bottom: 1px solid $color-Light-gray;

            h4 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
            }
        }

        .selectAllDiv {
            h5 {
                text-align: left;
                font-size: 16px;
                font: normal normal medium 16px/22px Gotham-Book;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }

            .Seperator {
                border-bottom: 1px solid $color-Light-gray;
            }

            .groupDiv {

                align-items: center;
                padding: 20px 0px 20px 0px;

                cursor: pointer;

                h5 {
                    text-align: left;
                    font: normal normal normal 16px/26px Gotham-Book;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    font-weight: 500;
                    margin-bottom: 0px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .profilImage {
                    width: 40px;
                    height: 40px;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                }

                .gropIcon {
                    min-width: 40px;
                    height: 40px;
                    background: #74613C 0% 0% no-repeat padding-box;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                    text-align: center;
                    padding: 5px;

                }
            }
        }
    }

    .addNewColumn {
        .addNewHeader {
            width: 100%;
            height: 80px;
            border-bottom: 1px solid $color-Light-gray;

            h6 {
                text-align: left;
                font: normal normal medium 16px/22px Gotham-Book;
                letter-spacing: 0px;
                color: #AAAAAA;
                opacity: 1;
                min-width: 115px;
            }

            .btnsDiv {
                display: flex;
            }

            .redButton {
                width: 117px;
                border: none;
                color: #FF3333;
                background: #FCE7E7 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                height: 44px;
                font-weight: 500;
                margin-left: 16px;
                margin-right: 20px;
                display: flex;
                align-items: center;

                .mainIcon {
                    margin-right: 4px;
                    margin-top: 5px;
                }
            }

            .greenButton {
                width: 109px;
                border: none;
                color: #31C52B;
                background: #E7F6E6 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                height: 44px;
                font-weight: 500;
                margin-right: 16px;

                .mainIcon {
                    margin-right: 5px;
                }
            }

            .TitleDiv {
                max-width: 320px;
                width: 100%;

                .Title {
                    max-width: 320px;
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                    border: 1px solid #DDDDDD;
                    border-radius: 8px;
                    opacity: 1;
                    height: 48px;

                }
            }
        }
    }
}