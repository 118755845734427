@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: #F5F5F5;


.EventLevelDiv {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .separator {
        border-right: 1px solid #AAAAAA;
        margin: 0px 15px;
    }

    .addUser {
        background: #74613C 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000026;
        border-radius: 8px;
        opacity: 1;

        height: 44px;
    }
}

.EventBox {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000001A;
    border-radius: 8px;
    opacity: 1;
    position: relative;

    .MuiTableCell-head {

        span {
            text-align: left;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 16px;
            color: #AAAAAA !important;
            text-transform: uppercase;
            opacity: 1;
        }
    }

    .react-select {
        outline: initial;
        box-shadow: none;

        .react-select__control {
            border-radius: 8px;
            border: none;
            background: transparent;
            outline: initial;
            box-shadow: none;
            width: 106px !important;
            height: 45px;

        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            svg {
                width: 20px;
                height: 20px;
                fill: black;
            }
        }

        .react-select__control--is-focused.react-select__control--menu-is-open {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .View {
        color: #74613C;
        cursor: pointer;
    }

    .list-group {
        background-color: transparent;

        .list-group-item {
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

        .ViewEdit {
            color: #74613C;
            width: 65px;
            margin-right: 5px;
        }

        .Delete {
            color: #FF2F2F;
            width: 65px;
            margin-right: 5px;
        }
    }


    .list-group-item:hover {
        background-color: transparent;
    }

    .list-group:hover {
        background-color: transparent;
    }

    .pagination {
        align-items: baseline;
        float: right;
        padding: 0px;
        margin-top: 0px;

        p {
            margin-bottom: 0px;
        }

        Button {
            margin-left: 10px;
            width: 24px;
            height: 36px;
            color: #74613C;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            border: 1px solid #DDDDDD;
        }

        input {
            width: 50px;
            padding: 0px 10px;
            height: 36px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            border: 1px solid #DDDDDD;
        }

    }
}