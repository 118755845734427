@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Gotham-Medium";
    src: url("../../assets/fonts//Gotham/GothamMedium.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: #CCCCCC;
$img :url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

.SocialSharingDiv {
    width: 100%;
    height: 780px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #0000001A;
    border-radius: 8px;
    opacity: 1;

    .chatListColumnDiv {
        height: 100%;
        border-right: 1px solid $color-Light-gray;
        padding: 0px;

        .chatListHeaderDiv {
            height: 80px;
            border-bottom: 1px solid $color-Light-gray;

            h4 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
            }

            .brownButton {
                background: #74613C 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 4px #00000026;
                border-radius: 8px;
                opacity: 1;
                border: none;

                height: 42px;
                font: normal normal medium 16px/22px Gotham-Book;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
                margin-top: 19px;
                margin-bottom: 19px;
            }
        }

        .guestListColumn {
            overflow: auto;
            height: 700px;

            h5 {
                text-align: left;
                font-size: 16px;
                font: normal normal medium 16px/22px Gotham-Book;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }

            .Seperator {
                border-bottom: 1px solid $color-Light-gray;
            }

            .groupDiv {

                align-items: center;
                padding: 20px 0px 20px 0px;

                cursor: pointer;

                h5 {
                    text-align: left;
                    font: normal normal normal 16px/26px Gotham-Book;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    font-weight: 500;
                    margin-bottom: 0px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .profilImage {
                    width: 40px;
                    height: 40px;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                }

                .gropIcon {
                    min-width: 40px;
                    height: 40px;
                    background: #74613C 0% 0% no-repeat padding-box;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                    text-align: center;
                    padding: 5px;

                }
            }

            .groupSelectDiv {

                align-items: center;
                padding: 20px 0px 20px 0px;

                cursor: pointer;
                background: #74613C1A 0% 0% no-repeat padding-box;
                opacity: 1;

                h5 {
                    text-align: left;
                    font: normal normal normal 16px/26px Gotham-Book;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    font-weight: 500;
                    margin-bottom: 0px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .profilImage {
                    width: 40px;
                    height: 40px;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                }

                .gropIcon {
                    min-width: 40px;
                    height: 40px;
                    background: #74613C 0% 0% no-repeat padding-box;
                    object-fit: none;
                    box-shadow: 0px 0px 5px #FFFFFF29;
                    border-radius: 10px;
                    opacity: 1;
                    text-align: center;
                    padding: 5px;

                }
            }
        }
    }

    .chatDashColumn {
        height: 100%;

        padding: 0px;

        .grpNameHeaderDiv {

            .chatDashSec {
                height: 80px;
                width: 100%;
                border-bottom: 1px solid $color-Light-gray;

                h5 {
                    width: 35%;
                    text-align: left;
                    font: normal normal 600 20px/31px Noto-Serif-JP;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }

                h6 {
                    width: 100%;

                }

                .brownButton {
                    background: #74613C 0% 0% no-repeat padding-box;
                    box-shadow: 0px 2px 4px #00000026;
                    border-radius: 8px;
                    opacity: 1;
                    border: none;
                    width: 220px;
                    height: 42px;
                    font: normal normal medium 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    opacity: 1;
                    margin-top: 19px;
                    margin-bottom: 19px;
                }

                .searchDiv {
                    padding-left: 15px;
                    border-left: 1px solid #AAAAAA;
                    width: 55%;

                    .form-control {
                        width: 100%;
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                        border: 1px solid #DDDDDD;
                        border-radius: 8px;
                        opacity: 1;
                        height: 42px;
                        padding: 0px 0px 3px 35px;
                        background-image: url('../../assets/images/socialSharing/searchicon.png');
                        background-position: left;
                        background-position-x: 10px;
                        background-size: 18px 18px;
                    }
                }

                .form-check:focus {
                    color: white;
                    box-shadow: none;
                }

                .form-switch .form-check-input {
                    width: 55px;
                    margin-left: -2.5em;
                    height: 30px;
                    margin-right: 10px;
                    color: white;
                    background-color: #CCCCCC;
                    border-color: $color-Light-gray;
                    background-image: $img;

                }

                .form-switch .form-check-input:focus {
                    box-shadow: none;
                    color: white;
                }

                .form-check-input:checked {
                    background-color: $theme-color-primary;
                    border-color: $theme-color-primary;
                    box-shadow: none;
                }

                .form-check-input:checked:focus {
                    box-shadow: none;
                    color: white;
                }
            }

            .chatScrollDiv {
                overflow: auto;

                height: 640px;
                width: 100%;

                .descText {
                    width: 200px;
                    margin-top: 35%;
                    text-align: center;
                    font: normal normal normal 18px/26px Gotham-Book;
                    letter-spacing: 0px;
                    color: #AAAAAA;
                    opacity: 1;
                }

                .adminChatTextDiv {
                    padding: 8px 8px 8px 20px;

                    .chatProfilImage {
                        border-radius: 5px !important;
                    }

                    .chatSenderIcon {
                        background: #F5F3EE 0% 0% no-repeat padding-box;
                        border: 0.5px solid #CCCCCC;
                        border-radius: 4px;
                        opacity: 1;


                        .aIcon {
                            color: #74613C;
                            opacity: 0.8;
                        }
                    }

                    .chatSenderName {
                        text-align: left;
                        font: normal normal normal 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #AAAAAA;
                        opacity: 1;
                    }

                    .chatText {
                        max-width: 390px;
                        background: #74613C 0% 0% no-repeat padding-box;
                        box-shadow: 1px 1px 2px #CCCCCCB3;
                        border-radius: 6px;
                        opacity: 1;

                        p {
                            color: #FFFFFF;
                            text-align: left;
                            font: normal normal normal 16px/22px Gotham-Book;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                            opacity: 1;
                        }
                    }
                }

                .userChatTextDiv {
                    padding: 8px 20px 8px 20px;
                    display: grid;
                    text-align: right;
                    justify-items: end;
                    width: 100%;

                    .userImgIconDiv {

                        float: right;

                        .chatProfilImage {
                            text-align: right;
                            float: right;
                            border-radius: 5px !important;
                        }
                    }

                    .chatSenderIcon {
                        background: #F5F3EE 0% 0% no-repeat padding-box;
                        border: 0.5px solid #CCCCCC;
                        border-radius: 4px;
                        opacity: 1;


                        .aIcon {
                            color: #74613C;
                            opacity: 0.8;
                        }
                    }

                    .chatSenderName {
                        text-align: left;
                        font: normal normal normal 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #AAAAAA;
                        opacity: 1;
                    }

                    .chatText {
                        max-width: 390px;
                        background: #F5F3EE 0% 0% no-repeat padding-box;
                        box-shadow: 1px 1px 2px #CCCCCCB3;
                        border-radius: 6px;
                        opacity: 1;

                        p {
                            text-align: left;
                            font: normal normal normal 16px/22px Gotham-Book;
                            letter-spacing: 0px;
                            color: #000000;
                            opacity: 1;
                        }
                    }
                }

                .notAllowToResDiv {
                    padding: 8px 0px;
                    display: grid;
                    text-align: right;
                    justify-items: end;
                    width: 100%;

                    .notAllowToRes {
                        width: 100%;
                        background: #74613C1A 0% 0% no-repeat padding-box;
                        opacity: 1;

                        p {
                            text-align: center;
                            font: normal normal normal 14px/20px Gotham-Book;
                            letter-spacing: 0px;
                            color: #000000;
                            opacity: 1;
                        }
                    }

                }
            }

            .typeDiv {
                height: 60px;
                width: 100%;
                display: flex;
                background-color: #EEEEEE;
                align-items: center;


                .searchBtn {
                    height: 42px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #DDDDDD;
                    border-radius: 10px;
                    opacity: 1;

                    .searchIconbtn {
                        color: #74613C;
                        opacity: 0.7;
                    }
                }

                .brownButton {
                    background: #74613C 0% 0% no-repeat padding-box;
                    box-shadow: 0px 2px 4px #00000026;
                    border-radius: 8px;
                    opacity: 1;
                    border: none;
                    width: 200px;
                    height: 42px;
                    font: normal normal medium 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    opacity: 1;
                    margin-top: 19px;
                    margin-bottom: 19px;
                }

                .textTypeSearch {

                    width: 100%;

                    .form-control {
                        height: 42px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 0.5px solid #DDDDDD;
                        border-radius: 10px !important;
                        opacity: 1;
                        padding: 0px 12px 3px 12px;
                    }
                }

                .sendBrownButton {
                    background: #74613C 0% 0% no-repeat padding-box;
                    box-shadow: 0px 2px 4px #00000026;
                    border-radius: 8px;
                    opacity: 1;
                    border: none;

                    height: 42px;
                    font: normal normal medium 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    opacity: 1;
                }

                .sendDisableBtn {
                    background: #B1A895;
                    border-radius: 10px;
                    border: none;
                    height: 42px;
                    opacity: 1;
                }
            }
        }
    }

    .makeAnnColumn {
        border-left: 1px solid #AAAAAA;
        .makeAnnHeaderDiv {
            height: 80px;
            border-bottom: 1px solid $color-Light-gray;

            h5 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
        }

        .makeGrpTextDiv {

            height: 640px;
            width: 100%;


            .makeGrpSendToDiv {
                border-bottom: 1px solid $color-Light-gray;
                overflow: auto;
                height: 130px;
                padding: 20px 0px 20px 0px;

                .sendTodiv {
                    text-align: left;
                    min-width: 60px;
                    font: normal normal medium 14px/20px Gotham-Book;
                    letter-spacing: 0px;
                    color: #74613C;
                    opacity: 1;
                    margin-left: 15px;
                }

                .grpMembersName {
                    text-align: left;
                    font: normal normal normal 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                }
            }

            .makeGrpFormDiv {

                padding: 20px 0px 20px 0px;

                .titleDiv {
                    width: 100%;
                    padding: 0px 20px;

                    .form-control {
                        width: 188px;
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                        border: 1px solid #DDDDDD;
                        border-radius: 8px;
                        opacity: 1;
                        height: 42px;
                        width: 100%;

                    }

                    .textArea {
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                        border: 1px solid #DDDDDD;
                        border-radius: 8px;
                        opacity: 1;
                        width: 100%;
                        height: 160px;
                    }

                    .brownButton {
                        background: #74613C 0% 0% no-repeat padding-box;
                        box-shadow: 0px 2px 4px #00000026;
                        border-radius: 8px;
                        opacity: 1;
                        border: none;
                        width: 176px;
                        height: 42px;
                        font: normal normal medium 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #FFFFFF;

                    }
                }

                .thumbNailImg {
                    border-radius: 8px;
                    opacity: 1;
                    width: 38%;
                    margin-left: 22px;
                }

                .attachmentTitel {
                    text-align: left;
                    font: normal normal medium 14px/20px Gotham-Book;
                    letter-spacing: 0px;
                    color: #74613C;
                    opacity: 1;
                    margin-left: 22px;
                }

                .RemoveDiv {
                    cursor: pointer;

                    .Remove {
                        color: #FF1A1A;
                    }
                }
            }
        }

        .buttonDiv {
            width: 100%;

            .redButton {
                width: 109px;
                border: none;
                color: #FF3333;
                background: #FCE7E7 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                height: 44px;
                font-weight: 500;
                margin-left: 16px;
                margin-right: 20px;

                .mainIcon {
                    margin-right: 4px;
                }
            }

            .greenButton {
                width: 109px;
                border: none;
                color: #31C52B;
                background: #E7F6E6 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                height: 44px;
                font-weight: 500;
                margin-right: 16px;

                .mainIcon {
                    margin-right: 4px;
                }
            }
        }
    }

}

@media only screen and (min-width: 1275px) and (max-width: 1455px) {
    .SocialSharingDiv {
        .makeAnnColumn {
            .buttonDiv {
                width: 100%;

                .redButton {
                    width: 117px;
                    border: none;
                    color: #FF3333;
                    background: #FCE7E7 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 3px #CCCCCCB3;
                    border-radius: 8px;
                    opacity: 1;
                    height: 44px;
                    font-weight: 400 !important;
                    margin-left: 12px;
                    padding: 4px;
                    margin-right: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .mainIcon {
                        margin-right: 2px;
                        margin-top: 5px;
                    }
                }

                .greenButton {
                    width: 117px;
                    border: none;
                    color: #31C52B;
                    background: #E7F6E6 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 3px #CCCCCCB3;
                    border-radius: 8px;
                    opacity: 1;
                    height: 44px;
                    font-weight: 400 !important;
                    margin-right: 12px;
                    padding: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .mainIcon {
                        margin-right: 4px;
                        margin-top: 5px;
                    }
                }
            }
        }

        .chatDashColumn {
            height: 100%;

            padding: 0px;

            .grpNameHeaderDiv {

                .chatDashSec {
                    height: 80px;
                    width: 100%;
                    border-bottom: 1px solid $color-Light-gray;

                    h5 {
                        width: 35%;
                        text-align: left;
                        font: normal normal 600 20px/31px Noto-Serif-JP;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    h6 {
                        width: 120%;
                        text-align: center;
                        font: normal normal 13px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        padding-right: 26px;
                        font-weight: 500;
                    }

                    .brownButton {
                        background: #74613C 0% 0% no-repeat padding-box;
                        box-shadow: 0px 2px 4px #00000026;
                        border-radius: 8px;
                        opacity: 1;
                        border: none;
                        width: 220px;
                        height: 42px;
                        font: normal normal medium 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        opacity: 1;
                        margin-top: 19px;
                        margin-bottom: 19px;
                    }

                    .searchDiv {
                        // padding-left: 53px;
                        border-left: 1px solid #AAAAAA;
                        width: 69%;

                        .form-control {
                            width: 100%;
                            background: #F5F5F5 0% 0% no-repeat padding-box;
                            border: 1px solid #DDDDDD;
                            border-radius: 8px;
                            opacity: 1;
                            height: 42px;
                            padding: 0px 0px 3px 35px;
                            background-image: url('../../assets/images/socialSharing/searchicon.png');
                            background-position: left;
                            background-position-x: 10px;
                            background-size: 18px 18px;
                        }
                    }

                    .form-check:focus {
                        color: white;
                        box-shadow: none;
                    }

                    .form-switch .form-check-input {
                        width: 55px;
                        margin-left: -2.5em;
                        height: 30px;
                        margin-right: 10px;
                        color: white;
                        background-color: #CCCCCC;
                        border-color: $color-Light-gray;
                        background-image: $img;

                    }

                    .form-switch .form-check-input:focus {
                        box-shadow: none;
                        color: white;
                    }

                    .form-check-input:checked {
                        background-color: $theme-color-primary;
                        border-color: $theme-color-primary;
                        box-shadow: none;
                    }

                    .form-check-input:checked:focus {
                        box-shadow: none;
                        color: white;
                    }
                }

                .chatScrollDiv {
                    overflow: auto;

                    height: 640px;
                    width: 100%;

                    .descText {
                        width: 200px;
                        margin-top: 35%;
                        text-align: center;
                        font: normal normal normal 18px/26px Gotham-Book;
                        letter-spacing: 0px;
                        color: #AAAAAA;
                        opacity: 1;
                    }

                    .adminChatTextDiv {
                        padding: 8px 8px 8px 20px;

                        .chatProfilImage {
                            border-radius: 5px !important;
                        }

                        .chatSenderIcon {
                            background: #F5F3EE 0% 0% no-repeat padding-box;
                            border: 0.5px solid #CCCCCC;
                            border-radius: 4px;
                            opacity: 1;


                            .aIcon {
                                color: #74613C;
                                opacity: 0.8;
                            }
                        }

                        .chatSenderName {
                            text-align: left;
                            font: normal normal normal 16px/22px Gotham-Book;
                            letter-spacing: 0px;
                            color: #AAAAAA;
                            opacity: 1;
                        }

                        .chatText {
                            max-width: 390px;
                            background: #74613C 0% 0% no-repeat padding-box;
                            box-shadow: 1px 1px 2px #CCCCCCB3;
                            border-radius: 6px;
                            opacity: 1;

                            p {
                                color: #FFFFFF;
                                text-align: left;
                                font: normal normal normal 16px/22px Gotham-Book;
                                letter-spacing: 0px;
                                color: #FFFFFF;
                                opacity: 1;
                            }
                        }
                    }

                    .userChatTextDiv {
                        padding: 8px 20px 8px 20px;
                        display: grid;
                        text-align: right;
                        justify-items: end;
                        width: 100%;

                        .userImgIconDiv {

                            float: right;

                            .chatProfilImage {
                                text-align: right;
                                float: right;
                                border-radius: 5px !important;
                            }
                        }

                        .chatSenderIcon {
                            background: #F5F3EE 0% 0% no-repeat padding-box;
                            border: 0.5px solid #CCCCCC;
                            border-radius: 4px;
                            opacity: 1;


                            .aIcon {
                                color: #74613C;
                                opacity: 0.8;
                            }
                        }

                        .chatSenderName {
                            text-align: left;
                            font: normal normal normal 16px/22px Gotham-Book;
                            letter-spacing: 0px;
                            color: #AAAAAA;
                            opacity: 1;
                        }

                        .chatText {
                            max-width: 390px;
                            background: #F5F3EE 0% 0% no-repeat padding-box;
                            box-shadow: 1px 1px 2px #CCCCCCB3;
                            border-radius: 6px;
                            opacity: 1;

                            p {
                                text-align: left;
                                font: normal normal normal 16px/22px Gotham-Book;
                                letter-spacing: 0px;
                                color: #000000;
                                opacity: 1;
                            }
                        }
                    }

                    .notAllowToResDiv {
                        padding: 8px 0px;
                        display: grid;
                        text-align: right;
                        justify-items: end;
                        width: 100%;

                        .notAllowToRes {
                            width: 100%;
                            background: #74613C1A 0% 0% no-repeat padding-box;
                            opacity: 1;

                            p {
                                text-align: center;
                                font: normal normal normal 14px/20px Gotham-Book;
                                letter-spacing: 0px;
                                color: #000000;
                                opacity: 1;
                            }
                        }

                    }
                }

                .typeDiv {
                    height: 60px;
                    width: 100%;
                    display: flex;
                    background-color: #EEEEEE;
                    align-items: center;


                    .searchBtn {
                        height: 42px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 0.5px solid #DDDDDD;
                        border-radius: 10px;
                        opacity: 1;

                        .searchIconbtn {
                            color: #74613C;
                            opacity: 0.7;
                        }
                    }

                    .brownButton {
                        background: #74613C 0% 0% no-repeat padding-box;
                        box-shadow: 0px 2px 4px #00000026;
                        border-radius: 8px;
                        opacity: 1;
                        border: none;
                        width: 200px;
                        height: 42px;
                        font: normal normal medium 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        opacity: 1;
                        margin-top: 19px;
                        margin-bottom: 19px;
                    }

                    .textTypeSearch {

                        width: 100%;

                        .form-control {
                            height: 42px;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            border: 0.5px solid #DDDDDD;
                            border-radius: 10px !important;
                            opacity: 1;
                            padding: 0px 12px 3px 12px;
                        }
                    }

                    .sendBrownButton {
                        background: #74613C 0% 0% no-repeat padding-box;
                        box-shadow: 0px 2px 4px #00000026;
                        border-radius: 8px;
                        opacity: 1;
                        border: none;
                        height: 42px;
                        font: normal normal medium 16px/22px Gotham-Book;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        opacity: 1;
                    }

                    .sendDisableBtn {
                        background: #B1A895;
                        border-radius: 10px;
                        border: none;
                        height: 42px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}