@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: gray;

.AccessManagement {
    .card {
        background-color: #fff;
        width: 100%;
        min-height: 730px;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        margin-bottom: 35px;
        box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);

        .card-header {
            padding: 18px 15px;
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
                margin: 0px;
            }

            .UserRole {
                text-align: center;
                font: normal normal medium 18px/26px Gotham-Book;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }

            .react-select {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #DDDDDD;
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                    outline: initial;
                    box-shadow: none;
                    width: 180px;
                    height: 45px;
                    opacity: 1;
                    font-weight: 600;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: black;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
            border-bottom: 1px solid #AAAAAA;
            opacity: 1
        }

        .card-body {
            .cardDiv {
                margin-bottom: 20px;
                display: flex;
                align-items: center;

                .permission{
                    font-weight: 500;
                    padding-left: 8px;
                }

                .Title {
                    text-align: left;
                    font: normal normal normal 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                }

                .react-select {
                    outline: initial;
                    box-shadow: none;
                    width: 220px;

                    .react-select__control {
                        border-radius: 8px;
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                        outline: initial;
                        box-shadow: none;
                        width: 220px;
                        height: 48px;
                        border: 1px solid #DDDDDD;
                        opacity: 1;
                        font-weight: 600;
                    }

                    .react-select__indicator-separator {
                        display: none;
                    }

                    .react-select__dropdown-indicator {
                        svg {
                            width: 20px;
                            height: 20px;
                            fill: black;
                        }
                    }

                    .react-select__control--is-focused.react-select__control--menu-is-open {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }

                .Label {
                    text-align: left;
                    font: normal normal medium 16px/22px Gotham;
                    letter-spacing: 0px;
                    font-weight: 500;
                    color: #74613C;
                    opacity: 1;
                }
            }

            .Desc {
                text-align: center;
                font: normal normal normal 18px/26px Gotham-Book;
                letter-spacing: 0px;
                color: #AAAAAA;
                opacity: 1;
                margin-top: 35px;
            }
        }
    }
}