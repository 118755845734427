@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

.LoginPage {
    overflow: auto;
    height: 100vh;
    position: relative;

    .ImageMainDiv {
        width: 50%;
        height: 100%;
        background-image: url('../../../assets//images/loginModule/mainImage.png');
        background-repeat: no-repeat;
        background-size: 102%;

        .MainImage {
            width: 100%;
            height: 100vh;
        }
    }

    .LoginMainDiv {
        background: transparent linear-gradient(180deg, #fbf0dc 0%, #ffffff 100%) 0% 0% no-repeat padding-box;

        .LoginDiv {
            height: 100%;

            .logoImageDiv {
                padding-bottom: 90px;

                .logoImage {
                    rotate: -0.5deg;
                    width: 380px;
                    height: 90px;
                }
            }

            .CardDiv {
                width: 440px;
                height: 420px;
                text-align: center;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 7px #CCCCCC;
                border-radius: 15px;
                opacity: 1;

                .CardBodyDiv {
                    padding: 40px;

                    .Heading {
                        margin-bottom: 15px;
                        text-align: left;
                        font: normal normal bold 24px/35px Noto-Serif-JP !important;
                        letter-spacing: 0px;
                        color: #74613C;
                        opacity: 1;
                    }

                    .Desc {
                        margin-bottom: 22px;
                        text-align: left;
                        font: normal normal normal 14px/20px Gotham-Book !important;
                        letter-spacing: 0px;
                        color: #AAAAAA;
                        opacity: 1;
                    }

                    .password-toggle-icon {
                        position: relative;
                        top: -36px;
                        right: -160px;

                        cursor: pointer;
                    }

                    .small {
                        color: #df4b3b;
                        text-align: left;
                    }

                    #pswrdError {
                        margin-top: -22px;
                    }

                    .btn:focus {
                        border-color: #74613C;
                        box-shadow: 0 0 0 0.25rem #e1b7654d;
                    }

                    .forgotPassword {
                        margin-top: 25px;

                        a {
                            color: #74613C;
                            text-decoration: none;
                            font-weight: 500;
                        }
                    }

                    .InputSpace,
                    .form-floating {
                        height: calc(48px + 21px);
                        border-radius: 8px;
                        opacity: 1;

                        .form-control {
                            height: 48px;
                            background: #F5F5F5 0% 0% no-repeat padding-box;
                            border: 1px solid #DDDDDD;
                            border-radius: 8px;
                            opacity: 1;
                            padding: 0px 0px;
                            text-align: left;
                            font: normal normal normal 14px/20px;
                            letter-spacing: 0px;
                            color: #000000;
                            border: none;

                            input::-ms-reveal,
                            input::-ms-clear {
                                display: none;
                            }
                        }

                        input::-ms-reveal,
                        input::-ms-clear {
                            display: none;
                        }

                        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                            padding: 12px 12px 13px 12px !important;
                        }
                    }
                }
            }
        }
    }
}