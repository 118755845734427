@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: gray;

.ViewGuest {
    .loader{
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card {
        background-color: #fff;
        width: 100%;
        min-height: 500px;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);

        .card-header {
            padding: 13px 15px;
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                text-align: left;
                font: normal normal 600 20px/29px Noto-Serif-JP;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
                margin: 0px;
            }

            .ButtonsDiv {
                display: flex;

                .editButtonGuest {
                    color: white;

                    width: 120px;
                    height: 45px;
                    background: #74613C 0% 0% no-repeat padding-box;
                    box-shadow: 0px 2px 4px #00000026;
                    border-radius: 8px;
                    opacity: 1;
                    border: none;
                    outline: none;
                }
            }

            .react-select {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #31C52B;
                    background: #EFFBEE;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            .react-selectChange {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #f92e05;
                    background: #fbeeee;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
            border-bottom: 1px solid #AAAAAA;
            opacity: 1;
            margin: 0px 15px;
        }

        .card-body {
            padding: 15px 0px 0px;
            display: flex !important;

            .DecDiv {
                display: flex;
                flex: 0.75;

                .FirstGroup {
                    text-transform: uppercase !important;
                    font: normal normal medium 16px/22px Gotham;
                    letter-spacing: 0px;
                    color: #74613C;
                    text-transform: uppercase;
                    opacity: 1;
                }

                .SecondGroup {
                    .levelText {
                        text-transform: lowercase;
                    }

                    .levelText::first-letter {
                        text-transform: uppercase;
                    }

                    .viewLink {
                        text-align: left;
                        text-decoration: underline;
                        letter-spacing: 0px;
                        color: #74613C;
                        opacity: 1;
                        padding-left: 10px;
                        cursor: pointer;
                    }
                }

                .list-group {
                    border: none;

                    .boldText {
                        font-weight: 500;
                        width: 165px;
                    }

                    .list-group-item {
                        border: none;
                        display: flex;

                        .ageText {
                            color: #AAAAAA;
                            padding-left: 10px;
                            margin: 0px;
                        }
                    }

                    .imgDivText {
                        padding-left: 110px;

                        .titleText {
                            text-align: left;
                            font: normal normal medium 16px/22px Gotham;
                            letter-spacing: 0px;
                            color: #74613C;
                            text-transform: uppercase;
                            opacity: 1;
                            padding-left: 20px;
                            margin-bottom: 0px;
                            font-weight: 500;
                        }

                        .mainImgPro {
                            width: 200px;
                            height: 200px;
                        }
                    }
                }

                .LastGroup {
                    display: flex;
                    flex-direction: row;

                    .mainIcon {
                        width: 150px;
                        height: 150px;
                        background: transparent;
                        border-radius: 8px;
                        opacity: 1;
                        border: none;
                    }

                    .videoPlayIcon {
                        left: 109px;
                        position: relative;
                    }
                }
            }
        }
        .mainImgPro {
            width: 200px;
            height: 200px;
            margin: 10px;
            border-radius: 15px;
        }

        .advanceTitle {
            margin: 0px !important;
            text-align: left;
            font: normal normal medium 16px/22px Gotham !important;
            letter-spacing: 0px;
            color: #74613C;
            opacity: 1;
            font-weight: 500;
            padding: 10px 15px 0px;
        }

        .advanceTableMainDiv {
            margin: 5px 15px;

            .AdvanceBox {
                .css-1ygcj2i-MuiTableCell-root {
                    padding: 6px 16px;
                }

                .css-1bkf9mw-MuiPaper-root {
                    box-shadow: none !important;

                    .css-1e2jvfq-MuiToolbar-root {
                        padding: 0px;
                    }

                    .css-wn7kgi-MuiToolbar-root {
                        background-color: white;
                        border-radius: 8px;
                    }

                    .css-1alss1r-MuiTableContainer-root {
                        padding-top: 0px;
                        padding-left: 10px;
                    }
                }

                .MuiTableCell-head {
                    span {
                        text-align: left;
                        letter-spacing: 0px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #AAAAAA !important;
                        opacity: 1;
                        cursor: default !important;
                    }
                }

                .MuiTableBody-root {
                    .MuiTableCell-root {
                        .sheduleList {
                            display: flex !important;

                            .sheduleOptions {
                                width: 24px;
                                height: 24px;
                                background: #74613C 0% 0% no-repeat padding-box;
                                opacity: 1;
                                border-radius: 13px;
                                margin: 0px 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .sheduleValues {
                                    color: #FFFFFF;
                                    margin: 0px;
                                }
                            }

                            .sheduleOptionsBlur {
                                width: 24px;
                                height: 24px;
                                background: #F5F3EE 0% 0% no-repeat padding-box;
                                border: 1px solid #74613C;
                                opacity: 1;
                                border-radius: 13px;
                                margin: 0px 3px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .sheduleValues {
                                    color: #74613C;
                                    margin: 0px;
                                }
                            }
                        }

                        i:first-letter {
                            text-transform: uppercase
                        }
                    }

                    .MuiTableCell-root:first-letter {
                        text-transform: uppercase
                    }
                }
            }
        }
    }
}