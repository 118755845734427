@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: #F5F5F5;


.ActivitySearchDiv {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .selButton {
        background: #74613C;
        border: 1px solid #74613C;
        border-radius: 8px;
        margin: 0px 6px;
        height: 45px;
        padding: 0px 8px;
        color: #fff;
        outline: none;
        min-width: 98px;
        max-width: 98px;
    }

    .UnselButton {
        background-color: transparent;
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        margin: 0px 6px;
        height: 45px;
        padding: 0px 8px;
        color: #000000;
        outline: none;
        min-width: 98px;
        max-width: 98px;
    }

    .selButton:focus,
    .UnselButton:focus,
    .allUnselButton:focus,
    .allSelButton:focus {
        outline: none;
        color: #fff;
        border-color: #74613C;
        box-shadow: none;
    }

    .separator {
        border-right: 1px solid #AAAAAA;
        opacity: 1;
        margin: 0px 15px 0px 10px;
    }
}

.ActivityBox {

    border-radius: 8px;
    opacity: 1;
    position: relative;

    .MuiTableCell-head {

        span {
            text-align: left;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 16px;
            color: #AAAAAA !important;
            opacity: 1;
        }
    }

    .viewLink {
        text-align: left;
        text-decoration: underline;
        letter-spacing: 0px;
        color: #74613C;
        opacity: 1;
        padding-left: 10px;
        cursor: pointer;
    }

    .MuiTableBody-root {
        .MuiTableCell-root {
            text-transform: lowercase;

            i:first-letter {
                text-transform: uppercase
            }
        }

        .MuiTableCell-root:first-letter {
            text-transform: uppercase
        }

        .react-select {
            text-transform: capitalize;
        }
    }


    .RecipeText {
        text-transform: capitalize !important;
    }

    .react-select {
        outline: initial;
        box-shadow: none;

        .ActiveClass {
            color: #31C52B;
        }

        .InactiveClass {
            color: #FF2F2F;
        }

        .react-select__control {
            border-radius: 8px;
            border: none;
            background: transparent;
            outline: initial;
            box-shadow: none;
            width: 116px !important;
            height: 45px;
            font-weight: 600;

            .css-15lsz6c-indicatorContainer:focus {
                padding: 0px 8px 0px 0px;
            }

            .css-1xc3v61-indicatorContainer {
                padding: 0px 8px 0px 0px;
            }
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            svg {
                width: 20px;
                height: 20px;
                fill: #74613C;
            }
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            svg {
                width: 20px;
                height: 20px;
                fill: #74613C;
            }
        }

        .react-select__control--is-focused.react-select__control--menu-is-open {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .View {
        color: #74613C;
        cursor: pointer;
        text-transform: capitalize !important;

        .list-group {

            background-color: transparent;

            .list-group-item {
                border: none;
                background-color: transparent;
                cursor: pointer;
            }

            .ViewEditNotClick {
                color: #74613C;
                width: 65px;
                cursor: no-drop;
            }

            .ViewEditClick {
                color: #74613C;
                width: 65px;
                cursor: pointer;
            }

            .ViewEdit {
                color: #74613C;
                width: 65px;
                margin-right: 10px;
            }

            .Delete {
                color: #FF2F2F;
                width: 70px;
            }
        }
    }

    .paginationBlock {
        .rowsSelect {
            .react-select__control {
                width: 62px !important;

                .css-1xc3v61-indicatorContainer {
                    padding: 0px 10px 0px 0px !important;
                }

                .css-15lsz6c-indicatorContainer {
                    padding: 0px 10px 0px 0px !important;
                }
            }
        }
    }
}