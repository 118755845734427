@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

.event-table {

    .ContentTabsContainer {
        position: relative;

        .TabsDiv {
            max-width: 44%;
            display: grid;
            grid-template-columns: 1.2fr 1.2fr;
            margin-left: 25px;
            height: 64px;
            box-shadow: none;
            border-radius: 8px 8px 0px 0px;
            opacity: 1;
            margin-bottom: -2px;
            font: normal normal medium 18px/26px Gotham !important;

            li {
                height: 100%;


                button {
                    height: 100%;
                    width: 100%;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    opacity: 1;
                    outline: none;
                    font-size: 18px;
                    font-weight: 500;
                }

                button.active {
                    height: 100%;
                    width: 100%;
                    background: #74613C 0% 0% no-repeat padding-box;
                    box-shadow: 0px 1px 6px #0000001A;
                    border-radius: 8px 8px 0px 0px;
                    opacity: 1;
                    outline: none;

                    text-align: center;
                    font: normal normal medium 20px/28px Gotham-Book !important;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                }
            }

            .mb-3 {
                border: none;

                .nav-item {
                    width: 386px;

                    .a {
                        color: red;
                    }
                }

                .nav-link {
                    color: #AAAAAA;
                    background-color: #fff;
                    flex: 0.33;
                }

                .nav-link.active {}

                .nav-link.active {
                    color: #386CB5 !important;
                    background-color: #fff;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-bottom: 3px solid #386CB5;
                }
            }
        }

        .buttonDiv {
            position: absolute;
            top: 0px;
            right: 0px;
            opacity: 1;

            .addContent {
                background: #74613C 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000026;
                border-radius: 8px;
                opacity: 1;
                height: 44px;
            }
        }
    }
}