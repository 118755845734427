.yearly-report{
    .year-changer{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        border: 0.5px solid rgb(239, 239, 239);
        font-size: 14px;
        font-weight: 500;
        color: #74613C;

        .date-box{
            flex: 1;
        }

        .arrow{
            cursor: pointer;
            width: 50px;
            outline: none;
            border: none;
            background-color: transparent;
        }

        .arrow-left{
            border-right: 0.5px solid rgb(239, 239, 239);
        }

        .arrow-right{
            border-left: 0.5px solid rgb(239, 239, 239);
            text-align: end;
        }

        .disabled{
            color: #AAAAAA;
        }

        button[disabled]{
            opacity: 0.3;
        }
    }
    
    .progress-report{
        .overall-report{
            margin: 30px 0 ;
            text-align: center;
        }

        .unfilled-circular-progress{
            position: relative;
            width: fit-content;
            margin: 0 auto;

            .filled-circular-progress{
                position: absolute;
                top: 0;
                left: 0;
            }
            .text{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-weight: 500;
                font-size: 22px;
            }
        }

        .schedule{
            .schedule-item-box{
                border-top: 0.5px solid #dddcdc;
                
                .unfilled-circular-progress{
                    margin: 0;
                    .text{
                        font-size: 17px;
                    }
                }
    
                .schedule-item-content{
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    margin: 20px auto;
    
                    .title{
                        font-weight: 500;
                        width: 400px;
                        font-size: 17px;
                        color: #696969;
                    }
                }
            }
        }
    }
}