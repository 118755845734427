.modal-content{
    border: none;
    background-color: transparent;
  }
  
  .add-edit-manual-modal{
    background: #fff 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;  
    border-radius: 8px;
  
    .heading{
        color: #74613C;
        font-weight: 600;
        font-size: 20px;
        font-family: Noto-Serif-JP;
        text-align: center;
        
    }
    
    .Label {
        text-align: left;
        font: normal normal medium 16px/22px Gotham-Book;
        letter-spacing: 0px;
        font-weight: 500;
        color: #74613C;
        opacity: 1;


    }

    input{
        width: 100%;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border: 1px solid #dddddd;
        border-radius: 8px;
        opacity: 1;
        height: 48px;
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        &:focus{
            border: 1px solid #dddddd;
            background: #F5F5F5 0% 0% no-repeat padding-box;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    button{
        width: 100px;
        text-align: center;
    }

    .file-link{
        font-size: 13.5px;
    }

  
    .redButton {
        border: none;
        color: #FF3333;
        background: #FCE7E7 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #CCCCCCB3;
        border-radius: 8px;
        opacity: 1;
        height: 44px;
        outline: none;
    }
  
    .greenButton {
        border: none;
        color: #31C52B;
        background: #E7F6E6 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #CCCCCCB3;
        border-radius: 8px;
        opacity: 1;
        height: 44px;
        outline: none

    }

    .upload-btn-container{
    
        .upload-multiple-files-btn{
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px;
            background-color: #74613C;
            color: #fff;
            outline: none;
            font-weight: 500;
            padding: 7px 15px;
            border-radius: 9px;
            outline: none;
            border: none;
            cursor: pointer;
            justify-content: center;
            text-align: center;
            width: fit-content;

        
            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 10px;
                width: fit-content;
            }
        }
        
        .old-doc{
            text-overflow: ellipsis;
            overflow: hidden;
            a{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        
        .file-conatiner{
        
            .file-box{
                background-color: #F8F9FA;
                display: flex;
                
                .file-name{
                    color: #74613C;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-right: 5px;
                    flex-grow: 1;
                    width: 80%;
                    text-wrap: nowrap;
                    font-size: 13px;
                }
            }
    
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        
        .remove-btn{
            cursor: pointer;
        }
    
        
    }
  }

  
  