@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: gray;

.AddUser {
    .loader{
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card {
        background-color: #fff;
        width: 100%;
        height: 790px;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);

        .card-header {
            padding: 20px 20px;
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4 {
                text-align: left;
                letter-spacing: 0px;
                color: #74613C;
                opacity: 1;
                font: normal normal 600 20px/29px Noto-Serif-JP;
            }

            .redButton {
                border: none;
                color: #FF3333;
                background: #FCE7E7 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                width: 118px;
                height: 44px;

                .mainIcon {
                    margin-bottom: 2px;
                }
            }

            .greenButton {
                border: none;
                color: #31C52B;
                background: #E7F6E6 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px #CCCCCCB3;
                border-radius: 8px;
                opacity: 1;
                width: 118px;
                height: 44px;

                .mainIcon {
                    margin-bottom: 4px;
                }
            }

            .react-select {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid greenyellow;
                    background: #EFFBEE;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
            border: 0.5px solid #AAAAAA;
            opacity: 1;
            margin: 0px 20px;
        }

        .error-message{
            color: red;
            font-size: 13px;
            height: 13px;
        }

        .card-body {
            padding: 30px 35px;

            .SecondSeparator {
                border: 1px solid #CCCCCC;
                opacity: 1;
            }

            .CardHeaderSec {
                padding-top: 20px;
                padding-bottom: 20px;
                margin-left: -15px;
                background-color: transparent;
                border: none;
                display: flex;
                justify-content: space-between;

                h4 {
                    text-align: left;
                    letter-spacing: 0px;
                    color: #74613C;
                    opacity: 1;
                    font: normal normal 600 20px/29px Noto-Serif-JP;
                }
            }

            .cardDiv {

                .Title {
                    width: 360px;
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                    border: 1px solid #DDDDDD;
                    border-radius: 8px;
                    opacity: 1;
                    height: 48px;
                }

                .Label {
                    text-align: left;
                    font: normal normal medium 16px/22px Gotham-Book;
                    letter-spacing: 0px;
                    font-weight: 500;
                    color: #74613C;
                    opacity: 1;
                }

                .GuestUserProgramDiv {
                    width: 360px;
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                    border-radius: 8px;
                    opacity: 1;
                    height: 48px;

                    .react-select {
                        outline: initial;
                        box-shadow: none;

                        .react-select__control {
                            border-radius: 8px;
                            border: 1px solid #d7d7d7;
                            background: #f5f5f5 !important;
                            outline: initial;
                            box-shadow: none;
                            width: 360px !important;
                            height: 48px;
                            text-align-last: left;
                        }

                        .react-select__indicator-separator {
                            display: none;
                        }

                        .react-select__dropdown-indicator {
                            svg {
                                width: 20px;
                                height: 20px;
                                fill: #74613C;
                            }
                        }

                        .react-select__control--is-focused.react-select__control--menu-is-open {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }

                .dateBlock {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 360px;
                    background: #F5F5F5 0% 0% no-repeat padding-box;
                    border: 1px solid #DDDDDD;
                    border-radius: 8px;
                    opacity: 1;
                    height: 48px;

                    .dayDateTextDiv {
                        display: flex;
                        align-items: center;
                        flex: 0.9;
                        padding-left: 10px;

                        .dayDateTextPlaceholder {
                            color: #495057;
                            opacity: 0.9;
                            margin: 0px;
                            font-weight: 400;
                        }
                        
                        .dayDateText {
                            margin: 0px;
                        }
                    }

                    .datePickerDiv {
                        flex: 0.1;

                        .calIcon {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .phoneNoInput {
                    .react-tel-input .form-control {
                        width: 360px;
                        background: #F5F5F5 0% 0% no-repeat padding-box;
                        border: 1px solid #DDDDDD;
                        border-radius: 8px;
                        opacity: 1;
                        height: 48px;
                    }
                }
            }
        }
    }
}