$color-brown: #74613C;
$color-cream: #F5F3EE;

.sidebar {
    min-width: 300px;
    max-width: 300px;
    transition: all 0.5s;
    position: fixed;
    overflow: hidden;
    z-index: 8;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #CCCCCC;
    opacity: 1;

    a {
        color: black;
        height: 100%;
        padding: 0.7rem 1rem !important;
    }

    .sidebar.is-open {
        margin-left: 0;
        transition: 0.5s;
    }

    .SidebarHeader {
        padding-top: 20px;
        padding-bottom: 20px;
        background: #fff;

        .mainIcon {
            position: relative;
        }
    }

    .separator {
        border-bottom: 0.5px solid #6C757D;
        margin: 0px 20px;
        opacity: 0.5;
    }

    .sideMenu {
        padding: 20px 0px 0px 0px;
        position: relative;
        height: 100vh;
        width: 100%;
        margin-right: 0px;

        .nav-item {
            height: 50px;
            padding-left: 20px;
        }

        .nav-item:hover {
            background: $color-cream;

            a {
                color: black;
                font-weight: 600;
            }
        }

        .active {
            padding-left: 15px;
            color: $color-brown;

            a {
                color: $color-brown;
                height: 100%;
                padding: 0.7rem 1rem !important;
                font-weight: 500;
                letter-spacing: 0px;
                opacity: 1;
            }
        }

        .active:hover {
            padding-left: 15px;
            color: $color-brown;

            a {
                color: $color-brown;
                height: 100%;
                padding: 0.7rem 1rem !important;
                font-weight: 500;
                letter-spacing: 0px;
                opacity: 1;
            }
        }

        .nav-item.active {
            background: $color-cream;
            border-left: 5px solid $color-brown;
            color: $color-brown;
        }

        li a.dropdown-toggle::after {
            display: inline-flex;
            position: relative;
            left: 60%;
            top: 10%;
        }

        .sidebar-header>span {
            position: relative;
            float: right;
            margin: 0.5em;
            font-size: 2rem;
            cursor: pointer;
            display: none;
        }
    }
}