@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,700&display=swap');

@font-face {
    font-family: "Gotham-Book";
    src: url("../../../assets/fonts//Gotham/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: "Noto-Serif-JP";
    src: url("../../../assets/fonts/NotoSerifJP/NotoSerifJP-Bold.otf") format("truetype");
}

$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: gray;

.ViewProgramsFeedback {
    .loader{
        width: 100%;
        height: calc(100vh - 90px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card {
        background-color: #fff;
        width: 100%;
        min-height: 790px;
        border-radius: 10px;
        padding: 0px;
        margin: 0px;
        box-shadow: 0px 1px 6px rgb(0 0 0 / 10%);

        .card-header {
            padding: 18px 15px;
            background-color: transparent;
            border: none;

            h6 {
                width:130px;
                color: #74613C;
                margin: 0;
            }

            .selButton {
                background: #74613C;
                border: 1px solid #74613C;
                border-radius: 8px;
                margin: 0px 6px;
                height: 45px;
                padding: 0px 20px;
                color: #fff;
                outline: none;

            }

            .react-select {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;
                cursor: default;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #31C52B;
                    background: #EFFBEE;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                        opacity: 1;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            .react-selectChange {
                outline: initial;
                box-shadow: none;
                margin-left: 10px;
                cursor: default;

                .ActiveClass {
                    color: #31C52B;
                }

                .InactiveClass {
                    color: #FF2F2F;
                }

                .react-select__control {
                    border-radius: 8px;
                    border: 1px solid #f92e05;
                    background: #fbeeee;
                    outline: initial;
                    box-shadow: none;
                    width: 120px;
                    height: 45px;
                }

                .react-select__indicator-separator {
                    display: none;
                }

                .react-select__dropdown-indicator {
                    svg {
                        width: 20px;
                        height: 20px;
                        fill: #74613C;
                    }
                }

                .react-select__control--is-focused.react-select__control--menu-is-open {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .separator {
            border-bottom: 1px solid #AAAAAA;
            opacity: 1;
        }

        .card-body {
            padding: 30px;

            .question-box{
                margin-bottom: 20px;
                .question{
                    color: #74613C;
                    font-weight: 500;
                    margin-bottom: 10px;
                    .question-number{
                        width: 30px;
                        display: inline-block;
                    }
                }

                .subquestion-box{
                    margin-left: 35px;
                    color: $color-Light-gray;
                    font-weight: 500;
                    font-size: 15px;

                    .answer-box{
                        color: black;
                        font-weight: 400;
                        font-size: 14px;
                        
                        .text-answer{
                            background-color: #f5f4f4;
                            border-radius: 5px;
                            border: .5px solid #ddd;
                            margin: 10px 0;
                            padding: 10px;
                            margin-bottom: 20px;
                            
                        }

                        .rating-answer{
                            display: flex;
                            align-items: center;
                            .radio-box{
                                width: 70px;

                                input {
                                    accent-color: #74613C !important;
                                }
                            }
                            margin-bottom: 20px;
                        }
                    }
                }
            }

        }
    }
}
