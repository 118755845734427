$theme-color-primary: #74613C;
$theme-color-secondary: #F5F3EE;
$theme-color-white: #fff;
$color-Light-gray: #F5F5F5;


.LevelDiv {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;

    .FormGroup {
        position: relative;
        display: -webkit-inline-box;
        align-self: center;
        margin: 0px !important;


        .form-label {
            cursor: text;
            padding: 0px !important;
            color: #171725;
            font-size: 16px;
            font-weight: bold;
            text-align-last: center;
            position: relative;
            margin-top: 10px;
            margin-right: 10px;
        }

        .react-select {
            outline: initial;
            box-shadow: none;
            margin-left: 10px;

            .react-select__control {
                border-radius: 8px;
                border: 1px solid #d7d7d7;
                background: $color-Light-gray;
                outline: initial;
                box-shadow: none;
                width: 160px;
                height: 45px;
                text-align-last: left;
            }

            .react-select__indicator-separator {
                display: none;
            }

            .react-select__dropdown-indicator {
                svg {
                    width: 20px;
                    height: 20px;
                    fill: #74613C;
                }
            }

            .react-select__control--is-focused.react-select__control--menu-is-open {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .DurationDiv {
        margin-left: 20px;


        .Dash {
            font-weight: 600;
            margin: 0px 8px;
        }

        .form-label {
            cursor: text;
            padding: 0px !important;
            color: #171725;
            font-size: 16px;
            font-weight: bold;
            text-align-last: center;
            position: relative;
            margin-top: 6px;
            margin-right: 10px;
        }
    }

    .separator {
        border-right: 1px solid #AAAAAA;
        opacity: 1
    }



}

.AssessmentBox {
    .css-1ygcj2i-MuiTableCell-root {
        padding: 6px 16px;
    }

    .css-1bkf9mw-MuiPaper-root {
        .css-wn7kgi-MuiToolbar-root {
            background-color: white;
            border-radius: 8px;
        }
    }

    .MuiTableCell-head {

        span {
            text-align: left;
            letter-spacing: 0px;
            font-weight: 500;
            font-size: 16px;
            color: #AAAAAA !important;
            opacity: 1;

            svg {
                color: #000000 !important;
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-root {

            i:first-letter {
                text-transform: uppercase
            }
        }

        .MuiTableCell-root:first-letter {
            text-transform: uppercase
        }

        .react-select {
            text-transform: capitalize;
        }
    }

    .react-select {
        outline: initial;
        box-shadow: none;

        .react-select__control {
            border-radius: 8px;
            border: none;
            background: transparent;
            outline: initial;
            box-shadow: none;
            width: 90px;
            height: 45px;
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            svg {
                width: 20px;
                height: 20px;
                fill: #74613C;
            }
        }

        .react-select__control--is-focused.react-select__control--menu-is-open {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    .View {
        color: #74613C;
        cursor: pointer;
    }

}